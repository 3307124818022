<template>
  <div class="right">
    <a-space>
      <a-button type="primary" @click="clickXlsxBtn">Excel Download</a-button>
    </a-space>
    <div class="gap"></div>
  </div>
</template>
<script>
export default {
  props: {
    btnIsShow: {
      default: true,
      type: Boolean,
    },
  },
  methods: {
    clickXlsxBtn() {
      this.$emit('clickXlsx');
    },
  },
};
</script>
<style lang="scss">
.gap {
  height: 20px;
}
</style>