<template>
  <div class="production-sales-history page">
    <h1 class="page-header">HCP인증요청 통계</h1>

    <div class="container single">
      <div class="table-top-ui">
        <SearchDate
          :propsDate="propsDate"
          :selectedBtn="selectedBtn"
          @changeDate="changeDate"
          @clickDate="clickDate"
          @clickDateBtn="clickDateBtn"
        />

        <!-- 채널선택 콤보 -->
        <SelectBox :selectValue="selectChannel" :searchList="kakaoChannel" @updateSelect="kakaoChannelUpdateSelect" />

        <!-- period total -->
        <a-divider style="height: 1px; background-color: #c4c4c4" />
        <a-list-item class="result-box">
          <a-col :span="14"
            ><b>기간({{ reqFromDay }} ~ {{ reqToDay }})내 요청자 수: {{ periodTotal }}명</b></a-col
          >
          <a-col :span="10">
            <a-divider type="vertical" style="height: 20px; background-color: #c4c4c4" />
            {{ selectChannel }} 전체: {{ allTotal }} 명/ 오늘: {{ todayTotal }}명
          </a-col>
        </a-list-item>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import SearchDate from '@/components/common/adminForm/SearchDate.vue';
import topUI from '@/components/common/adminForm/SearchTopUIComp.vue';
import TopTotal from '@/components/common/adminForm/TopTotal.vue';
import Table from '@/components/common/adminForm/Table.vue';
import SelectBox from '@/components/common/v2.0/SelectBox.vue';

const formatService = require('@/utils/format.js');

export default {
  components: {
    topUI,
    Table,
    TopTotal,
    SelectBox,
    SearchDate,
  },
  data() {
    return {
      kakaoChannel: formatService.kakaoChannelAll(),
      userInfoTable: formatService.userInfoTableColumns(),
      List: null,
      xlsxData: [],
      selectChannel: '',
      selectedBtn: 'month1',
      propsDate: [this.$moment().subtract(30, 'days'), this.$moment()],
      searchValue: '',
      allTotal: 0,
      todayTotal: 0,
      periodTotal: 0,
      reqFromDay: '',
      reqToDay: '',
    };
  },
  async created() {
    this.getList();
  },
  methods: {
    async getList() {
      this.fromDate = this.propsDate[0].format('YYYYMMDD');
      this.toDate = this.propsDate[1].format('YYYYMMDD');
      await this.getHcpRequest(this).then((res) => {
        this.allTotal = res.data.all;
        this.todayTotal = res.data.today;
        this.periodTotal = res.data.periodTotal;
        this.reqFromDay = this.$moment(res.data.reqFromDay).format('YYYY년 MM월 DD일');
        this.reqToDay = this.$moment(res.data.reqToDay).format('YYYY년 MM월 DD일');
      });
      this.setXlsxData();
    },
    clickDate() {
      this.selectedBtn = '';
    },
    clickDateBtn(e) {
      this.selectedBtn = e;
    },
    clickReload() {
      // 컴포넌트 안에 있는 값들도 리셋시켜주어야함... 1. 컴포넌트 props 2. 컴포넌트 $refs
      this.searchValue = '';
      this.selectedBtn = 'month1'; // 초기화할때 버튼과 date 값을 초기화 해야함
      this.propsDate = [this.$moment().subtract(30, 'days'), this.$moment()];
      this.getList();
    },
    changeDate(e) {
      this.propsDate = e;
      this.getList();
    },
    // 검색어
    changeSearchWordComplete(e) {
      this.searchValue = e;
      this.getList();
    },
    changeText(e) {
      this.searchValue = e;
    },
    clickEditorBtn() {
      const reqData = new userInfoCRUDModel({});
      this.$refs.userInfoEditorPage.showModal(reqData);
    },
    tableClickRow(e) {
      console.log(e);
      this.$refs.userInfoEditorPage.showModal(e);
    },
    kakaoChannelUpdateSelect(e) {
      this.selectChannel = e;
      this.getList();
    },
    // 저장/수정/삭제 후 처리
    userEditorCompleteSaveData(e) {
      console.log('complete');
      // 신규저장시만 리로드
      // if (e.xpk > 0) return;
      this.getList();
    },
    xlsxSave() {
      let fileHeader = this.selectChannel === '' ? 'All' : this.selectChannel.substr(0, this.selectChannel.length - 1);
      console.log('fileHeader ', fileHeader);

      this.$helper.xlsxDownload(this.xlsxData, 'sheet1', 'MemberInfoList_' + fileHeader);
    },
    setXlsxData() {
      this.xlsxData = [];
      // for (const item of list) {
      //   this.xlsxData.push({
      //     전체요청자: this.allTotal,
      //     오늘요청자: this.todayTotal,
      //     기간요청자: this.periodTotal,
      //   });
      // }
    },
    ...mapActions(['getMembers', 'getHcpRequest']),
  },
  computed: {
    ...mapState(['members']),
  },
};
</script>

<style>
div#member-radio {
  position: absolute;
  top: 228px;
  left: 130px;
}
.result-box {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 60px;
  padding: 20px;
  background-color: #e8e8e8;
  border: 1px solid #d8d8d8;
}
</style>~/src/utils/columns