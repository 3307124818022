<template>
  <div class="production-sales-history page">
    <h1 class="page-header">채널톡 카드뉴스</h1>
    <logPage
      :chartData="chartData"
      :selectCardNews="selectCardNews"
      :searchList="searchList"
      :originData="originData"
      :subTableViewOpt="true"
      :isHcpNum="isHcpNum"
      @changeDate="changeDateHandler"
      @changeCardNews="changeCardNewsHandler"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { ChartData } from './chartData';
import logPage from './tab/FriendsCardNewsLog.vue';

const formatService = require('@/utils/format.js');

export default {
  components: {
    logPage,
  },
  data() {
    return {
      originData: null,
      tabValue: '1',
      selectEndPoint: 'friendsLogView',
      searchList: formatService.friendsCardNewsAll(),
      selectCardNews: 'test01',
      propsDate: [this.$moment().subtract(30, 'days'), this.$moment()],
      chartData: new ChartData({
        labels: formatService.setDateLabel(this.$moment().subtract(30, 'days'), this.$moment()),
        datasets: formatService.defaultChartData(),
      }),
      backgroundColorColumn: formatService.backgroundColorColumn(),
      isHcpNum: null,
    };
  },
  mounted() {
    this.getLog();
  },
  methods: {
    // onchangeTabs(value) {
    //   this.tabValue = value;
    //   this.selectChannel = '프로그랍-Autoimmune봇';
    //   this.searchList = formatService.kakaoChannel();
    //   if (value == 2) {
    //     this.selectChannel = '프로그랍AI 컨텐츠';
    //     this.searchList = formatService.contentName();
    //   }
    //   this.getLog();
    // },
    changeDateHandler(e) {
      this.propsDate = e;
      const startDate = this.propsDate[0];
      const endDate = this.propsDate[1];

      const dateArray = formatService.setDateLabel(startDate, endDate);
      this.chartData.updateLabels(dateArray);
      this.chartData.updateDatasets(formatService.defaultChartData());
      this.getLog();
    },
    changeCardNewsHandler(e) {
      this.selectCardNews = e;
      // this.getLog();
    },
    // 데이터읽기
    async getLog() {
      this.fromDate = this.propsDate[0].format('YYYYMMDD');
      this.toDate = this.propsDate[1].format('YYYYMMDD');
      await this.getFriendsLogs(this).then(() => {
        // hcp 인증수
        this.isHcpNum = this.logs.data2;
        // 기본데이터 처리
        const mapObj = new Map();
        this.logs.data.forEach((item) => {
          let key = item['utterance'];
          if (this.selectEndPoint === 'friendsLogView') {
            key = item['page'];
          }
          if (mapObj.has(key)) {
            mapObj.get(key).push(item);
          } else {
            mapObj.set(key, [item]);
          }
        });

        // 차트 라벨처리
        let label = Array.from(mapObj.keys());
        const chartLabel = new Map();
        label.forEach((element) => {
          chartLabel.set(element, element);
        });

        // 차트 데이터 처리
        // 오늘날자 처리
        let dateType = this.fromDate === this.toDate ? 'HH:00' : 'YYYY-MM-DD';
        const datasets = label.map((item, index) => {
          const data = {};
          mapObj
            .get(item)
            .map((item) => this.$moment(item.createdAt).format(dateType))
            .forEach((item) => {
              data[item] ? data[item]++ : (data[item] = 1);
            });
          return {
            label: chartLabel.get(item),
            backgroundColor: this.backgroundColorColumn[index],
            data,
          };
        });

        // table 데이터 처리
        let res = this.logs.data;
        if (0 < res.length) {
          res = res.map((item, index) => ({
            ...item,
            no: res.length - index,
            regDateLabel: this.$moment(item.createdAt).format('YYYY년 MM월DD일 a h:mm'),
            channelNameLbl:
              item.channelName === null ? item.channelName : item.channelName.substr(0, item.channelName.length - 1), // 봇 제거
          }));
        }
        this.originData = res;
        // console.log('', datasets);
        this.chartData.updateDatasets(datasets);
      });
    },
    ...mapActions(['getLogs', 'getFriendsLogs']),
  },
  computed: {
    ...mapState(['logs']),
  },
};
</script>

<style lang="scss">
.page-header {
  height: 100px;
}
</style>