<template>
  <a-layout-sider v-model="collapsed">
    <div class="logo">
      <img src="@/assets/img/logo.svg" alt="" />
    </div>
    <div class="menu-wrap">
      <a-menu class="menu" mode="inline" :default-selected-keys="[selectedKey]" :default-open-keys="[selectedSub]">
        <!-- 회원관리  -->
        <a-menu-item key="1">
          <router-link to="/userInfo">
            <a-icon type="user" />
            <span>회원관리</span>
          </router-link>
        </a-menu-item>

        <a-menu-item key="2">
          <router-link to="/logView">
            <a-icon type="logView" />
            <span>콘텐츠별 통계</span>
          </router-link>
        </a-menu-item>

        <a-menu-item key="3">
          <router-link to="/hcpRequestLog">
            <a-icon type="hcpRequestLog" />
            <span>HCP인증요청 통계</span>
          </router-link>
        </a-menu-item>

        <a-menu-item key="4">
          <router-link to="/friendsCardNewsLog">
            <a-icon type="friendsCardNewsLog" />
            <span>채널톡 카드뉴스</span>
          </router-link>
        </a-menu-item>

        <!-- 메뉴1 -->
        <!-- <a-sub-menu key="menu1" v-if="isAdmin">
          <span slot="title">
            <a-icon type="user" />
            <span>고객관리</span>
          </span>
          <a-menu-item key="menu1_1">
            <router-link :to="$url_list.ORGMANAGER">기관(고객)정보관리</router-link>
          </a-menu-item>
          <a-menu-item key="menu1_2"> <router-link :to="$url_list.BIZMANAGER">사업정보관리</router-link></a-menu-item>
          <a-menu-item key="menu1_3"> <router-link :to="$url_list.MAMANAGER">MA접근관리</router-link></a-menu-item>
        </a-sub-menu> -->
      </a-menu>

      <div class="dpVersion">Ver - {{ this.appVersion }}</div>
    </div>
  </a-layout-sider>
</template>
<script>
// import { eventBus } from '@/main';
import packageJson from '../../../package.json';

export default {
  data() {
    return {
      isAdmin: false,
      collapsed: false,
      selectedKey: '1',
      // selectedSub: 'sub2',
      // appVersion: this.$store.getters.distInfo.appVersion,
      appVersion: packageJson.version,
      // appVersion: process.env.VUE_APP_VERSION_CODE,
    };
  },
  created() {
    // 관리자 확인
    // this.isAdmin = this.$store.getters.userInfo.grade === '01' ? true : false;

    const P = this.$helper.urlSelector(this.$route.fullPath);
    this.selectedKey = P.key;
    this.selectedSub = P.sub;
    // console.log(this.selectedKey, this.selectedSub);

    // eventBus.$on('ToggleCollapsed', () => {
    //   this.collapsed = !this.collapsed;
    //   //   console.log(this.collapsed);
    // });
    console.log(packageJson.version);
  },
};
</script>

<style lang="scss">
.dpVersion {
  color: gray;
  font-size: 8pt;
  position: fixed;
  bottom: 0;
  padding: 10px;
}
.ant-layout-sider {
  position: relative;
  background: $primary-color !important;
  .logo {
    border-bottom: 1px solid #001937;
    padding: 5px 20px;
    color: white;
    background: #ffffff;
    img {
      height: 40px;
      display: block;
    }
  }
  .ant-menu-submenu > .ant-menu {
    background-color: #001937;
  }
  .menu-wrap {
    height: calc(100% - 60px);
    overflow-y: overlay;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, 0.6);
      border-radius: 0;
    }
  }

  .ant-menu-item-selected > a,
  .ant-menu-item-selected > .ant-menu-item > a:hover {
    color: $active-color;
  }
  .ant-menu {
    background-color: $primary-color;
    color: #fff;
    border-right: none;
    &:not(.ant-menu-horizontal) {
      .ant-menu-item-selected {
        // background-color: $primary-color;
        color: $active-color;
        background-color: #001937;
        a {
          color: $active-color;
          &:hover {
            color: $active-color;
          }
        }
      }
    }
    .ant-menu-submenu-selected {
      color: $active-color;
    }
    .ant-menu-submenu-title {
      cursor: pointer;
      &:hover {
        color: $active-color;
        .ant-menu-submenu-arrow::before {
          background: $active-color;
        }
        .ant-menu-submenu-arrow::after {
          background: $active-color;
        }
      }
    }
    .ant-menu-item {
      cursor: pointer;
      margin: 0;
      width: 100%;
      &:hover {
        color: $active-color;
      }
      &::after {
        border-color: $active-color;
        border-left: 3px solid $active-color;
        border-right: 0;
        left: 0;
        right: initial;
      }
      a {
        color: #fff;
        &:hover {
          color: $active-color;
        }
      }
    }
  }
}
</style>