<template>
  <div class="login-wrap">
    <div class="login-logo-wrap">
      <img src="@/assets/img/logo.svg" alt="" />
    </div>
    <a-form class="login-form" @submit.prevent="submitForm">
      <a-form-item>
        <a-input
          class="text-input"
          type="text"
          v-model="userId"
          size="large"
          placeholder="userId"
          ref="refEmail"
          :rules="[emailRules.required, emailRules.email]"
        />
      </a-form-item>
      <a-form-item>
        <a-input
          class="text-input"
          type="password"
          v-model="password"
          size="large"
          placeholder="Password"
          ref="refPassword"
          :rules="[passwordRules.required, passwordRules.counter]"
        />
      </a-form-item>
      <!-- !isUsernameValid ||  -->
      <a-button
        type="primary"
        html-type="submit"
        class="login-form-button"
        :disabled="!userId || !password"
        :class="!password ? 'disabled' : null"
      >
        Log in
      </a-button>
      <div class="hgap"></div>
      <a-button v-if="isDev" type="primary" class="login-form-button" @click="goSignUp"> 관리자등록 </a-button>
    </a-form>
    <!-- <p class="log">{{ isAuthorized }}</p> -->

    <!-- 회원등록(관리자등록) -->
    <SignUp ref="signUpPage" @completeSaveData="signUpPageCompleteSaveData" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SignUp from '@/components/SignUp.vue';

export default {
  components: {
    SignUp,
  },
  data() {
    return {
      // userId: 't@predict.kr', //
      // password: '1234!',
      userId: '', //
      password: '',
      logMessage: '',
      passwordRules: {
        required: (value) => !!value || '패스워드 입력이 필요합니다.',
        counter: (value) =>
          (value.length >= 4 && value.length <= 100) || '패스워드는 4글자 이상 100글자 이하 이어야 합니다.',
      },
      emailRules: {
        required: (value) => !!value || '아이디(이메일) 입력이 필요합니다.',
        counter: (value) => value.length <= 100 || 'Max 100 characters',
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || '아이디(이메일) 타입을 입력해주세요.';
        },
      },
      isDev: process.env.NODE_ENV === 'development',
    };
  },
  computed: {
    ...mapGetters(['isAuthorized']),
  },
  mounted() {
    if (process.env.NODE_ENV === 'development') {
      this.userId = 't@predict.kr';
      this.password = '1234!';
    }
    // this.goSignUp();
  },
  methods: {
    async submitForm() {
      this.signIn(this).then(() => {
        this.$router.push('/userInfo').catch(() => {});
      });
    },
    goSignUp() {
      this.$refs.signUpPage.showModal();
    },
    signUpPageCompleteSaveData() {},
    ...mapActions(['signIn']),
  },
};
</script>

<style lang="scss">
@import '~@/assets/scss/common';
.hgap {
  height: 10px;
}
.login-wrap {
  background: #fff;
  padding: 40px 80px 100px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.05);
  .login-logo-wrap {
    padding: 40px;
    text-align: center;
    img {
      height: 60px;
      margin-left: -23px;
    }
  }
  .login-form {
    width: 350px;
  }
  .login-form-button {
    background: $primary-color;
    border-color: $primary-color;
    color: #ffffff;
    border-radius: 0;
    height: 50px;
    width: 100%;
  }
}
</style>
