<template>
  <div class="production-sales-history page">
    <h1 class="page-header">콘텐츠별 통계</h1>
    <a-tabs default-active-key="1" @change="onchangeTabs">
      <a-tab-pane key="1" tab="버튼클릭로그">
        <menuTouchLog
          :chartData="chartData"
          :selectChannel="selectChannel"
          :searchList="searchList"
          :originData="originData"
          :subTableViewOpt="true"
          :tableList="tableList"
          :isHcpNum="isHcpNum"
          :isHcpWNum="isHcpWNum"
          @changeDate="changeDateHandler"
          @changeKakaoChannel="changeKakaoChannelHandler"
          @clickXlsx="clickXlsxHandler('버튼클릭로그')"
        />
      </a-tab-pane>
      <!-- <a-tab-pane key="2" tab="카드뉴스로그" force-render>
        <menuTouchLog
          :chartData="chartData"
          :selectChannel="selectContent"
          :searchList="searchList"
          :originData="originData"
          :subTableViewOpt="true"
          :tableList="tableList"
          :isHcpNum="isHcpNum"
          @changeDate="changeDateHandler"
          @changeKakaoChannel="changeKakaoChannelHandler"
          @clickXlsx="clickXlsxHandler('카드뉴스로그')"
        />
      </a-tab-pane> -->
    </a-tabs>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { ChartData } from './chartData';
import menuTouchLog from './tab/MenuTouchLog.vue';

const formatService = require('@/utils/format.js');

export default {
  components: {
    menuTouchLog,
  },
  data() {
    return {
      originData: null,
      tabValue: '1',
      selectEndPoint: 'logView',
      searchList: formatService.kakaoChannel(),
      selectChannel: '프로그랍-Autoimmune봇',
      selectContent: '프로그랍AI 컨텐츠',
      propsDate: [this.$moment().subtract(30, 'days'), this.$moment()],
      chartData: new ChartData({
        labels: formatService.setDateLabel(this.$moment().subtract(30, 'days'), this.$moment()),
        datasets: formatService.defaultChartData(),
      }),
      backgroundColorColumn: formatService.backgroundColorColumn(),
      tableList: null,
      logViewColumns: formatService.logViewColumn(),
      menuLogViewColumns: formatService.menuLogViewColumn(),
      isHcpNum: null,
      isHcpWNum: null,
    };
  },
  mounted() {
    this.tableList = this.menuLogViewColumns;
    this.getLog();
  },
  methods: {
    onchangeTabs(value) {
      this.tabValue = value;
      this.selectChannel = '프로그랍-Autoimmune봇';
      this.searchList = formatService.kakaoChannel();
      if (value == 2) {
        this.selectContent = '프로그랍AI 컨텐츠';
        this.searchList = formatService.contentName();
      }
      this.getLog();
    },
    changeDateHandler(e) {
      this.propsDate = e;
      const startDate = this.propsDate[0];
      const endDate = this.propsDate[1];

      const dateArray = formatService.setDateLabel(startDate, endDate);
      this.chartData.updateLabels(dateArray);
      this.chartData.updateDatasets(formatService.defaultChartData());
      this.getLog();
    },
    changeKakaoChannelHandler(e) {
      // console.log('', e);
      // 변수명을 퀵메뉴에서 채널명 변수를 같이 사용 selectChannel
      // 퀵메뉴 selectChannel <- 채녈명
      // 카드뉴스 selectContent 로 selectChannel을 찾아 같이 보내야 함
      this.selectContent = e;
      if (this.tabValue === 2) {
        this.selectChannel = this.checkKakaoChannel(e);
      } else {
        this.selectChannel = e;
      }
      // console.log('this.selectChannel', this.selectChannel);
      this.getLog();
    },
    checkKakaoChannel(content) {
      let returnVal = '';
      switch (content) {
        case '프로그랍AI 컨텐츠':
          returnVal = '프로그랍-Autoimmune봇';
          break;
        case '프로그랍TX 컨텐츠':
          returnVal = 'transplantation봇';
          break;
        case '아드바그랍 제품정보':
          returnVal = 'transplantation봇';
          break;
        case '하루날디 컨텐츠':
          returnVal = '하루날베타미가봇';
          break;
        case '베타미가 컨텐츠':
          returnVal = '하루날베타미가봇';
          break;
        case '비뇨의학과 학회일정':
          returnVal = '하루날베타미가봇';
          break;
      }
      return returnVal;
    },
    getLog() {
      switch (this.tabValue) {
        case '1':
          this.tableList = this.menuLogViewColumns;
          this.selectEndPoint = 'logView';
          break;
        case '2':
          this.tableList = this.logViewColumns;
          this.selectEndPoint = 'contentsLogView';
          break;
      }
      this.getBtnLog();
    },
    // 데이터읽기
    async getBtnLog() {
      this.fromDate = this.propsDate[0].format('YYYYMMDD');
      this.toDate = this.propsDate[1].format('YYYYMMDD');
      this.setQuery({
        fromDate: this.fromDate,
        toDate: this.toDate,
        content: this.selectChannel,
      });
      await this.getLogs(this).then(() => {
        // hcp 인증수
        this.isHcpNum = this.logs.data2;
        this.isHcpWNum = this.logs.data3;
        // 기본데이터 처리
        const mapObj = new Map();
        this.logs.data.forEach((item) => {
          let key = item['utterance'];
          if (this.selectEndPoint === 'contentsLogView') {
            key = item['page'];
          }
          if (mapObj.has(key)) {
            mapObj.get(key).push(item);
          } else {
            mapObj.set(key, [item]);
          }
        });

        // 차트 라벨처리
        let label = Array.from(mapObj.keys());
        const chartLabel = new Map();
        label.forEach((element) => {
          chartLabel.set(element, element);
        });

        // 차트 데이터 처리
        // 오늘날자 처리
        let dateType = this.fromDate === this.toDate ? 'HH:00' : 'YYYY-MM-DD';
        const datasets = label.map((item, index) => {
          const data = {};
          mapObj
            .get(item)
            .map((item) => this.$moment(item.createdAt).format(dateType))
            .forEach((item) => {
              data[item] ? data[item]++ : (data[item] = 1);
            });
          return {
            label: chartLabel.get(item),
            backgroundColor: this.backgroundColorColumn[index],
            data,
          };
        });

        // table 데이터 처리
        let res = this.logs.data;
        if (0 < res.length) {
          res = res.map((item, index) => ({
            ...item,
            no: res.length - index,
            regDateLabel: this.$moment(item.createdAt).format('YYYY년 MM월DD일 a h:mm'),
            channelNameLbl: this.changeChannelName(item.channelName),
            // channelNameLbl:
            //   item.channelName === null
            //     ? item.channelName
            //     : this.$helper.addChannelName(item.channelName.substr(0, item.channelName.length - 1)), // 봇 제거
          }));
        }
        // 퀵메뉴일때만
        // utterance groupby
        let newData = [];
        const newA = [];
        if (this.selectEndPoint === 'logView') {
          newData = this.$helper.groupBy(res, (res) => {
            return [res.utterance];
          });
          newData.forEach((E, i) => {
            const d = {
              channelNameLbl: E[0].channelNameLbl,
              title: E[0].utterance,
              utterance: E[0].utterance,
              page: E.length,
              data: E,
            };
            newA.push(d);
          });
          this.setXlsxData(newA);
          this.originData = newA;
          // console.log('', newA);
        }

        // 카드뉴스로그
        if (this.selectEndPoint === 'contentsLogView') {
          newData = this.$helper.groupBy(res, (res) => {
            return [res.page];
          });
          newData.forEach((E) => {
            const d = {
              endPoint: this.selectEndPoint,
              channelNameLbl: E[0].channelNameLbl,
              title: E[0].content + '/' + E[0].page + '페이지',
              content: E[0].content,
              queryPage: E[0].page,
              page: E[0].page + '페이지',
              pageClickNum: E.length,
              link1Count: E[0].link1Count,
              link2Count: E[0].link2Count,
              data: E,
            };
            newA.push(d);
          });
          // 페이지로 정렬
          newA.sort(function (a, b) {
            let queryPageA = a.queryPage;
            let queryPageB = b.queryPage;

            if (queryPageA < queryPageB) {
              return -1;
            }

            if (queryPageA > queryPageB) {
              return 1;
            }
            return 0; // 같을 경우
          });
          this.setXlsxData2(newA);
          this.originData = newA;
          // console.log('', newA);
        }
        console.log('', datasets, this.chartData);
        this.chartData.updateDatasets(datasets);
      });
    },
    changeChannelName(channelName) {
      let returnVal =
        channelName === null ? channelName : this.$helper.addChannelName(channelName.substr(0, channelName.length - 1)); // 봇 제거
      if (returnVal === 'transplantation') {
        returnVal = '프로그랍-Transplantation';
      }
      return returnVal;
    },
    clickXlsxHandler(pageType) {
      let targetData = this.xlsxData2;
      if (pageType === '버튼클릭로그') {
        targetData = this.xlsxData;
      }
      this.$helper.xlsxDownload(targetData, 'sheet1', pageType + '_');
    },
    // 버튼클릭데이터
    setXlsxData(list) {
      this.xlsxData = [];
      for (const item of list) {
        this.xlsxData.push({
          가입채널: item.channelNameLbl,
          퀵메뉴명: item.utterance,
          클릭수: item.page,
        });
      }
    },
    // 카드뉴스 데이터
    setXlsxData2(list) {
      this.xlsxData2 = [];
      for (const item of list) {
        this.xlsxData2.push({
          가입채널: item.channelNameLbl,
          콘텐츠명: item.content,
          페이지: item.page,
          링크1클릭: item.link1Count,
          링크2클릭: item.link2Count,
          클릭수: item.pageClickNum,
        });
      }
    },
    ...mapActions(['getLogs', 'setFromDay', 'setToDay', 'setQuery']),
  },
  computed: {
    ...mapState(['logs']),
  },
};
</script>