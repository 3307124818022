<template>
  <a-radio-group :value="selectedBtn" button-style="solid">
    <a-radio-button value="today" @click="clickBtn"> Today </a-radio-button>
    <a-radio-button value="week" @click="clickBtn"> a Week </a-radio-button>
    <a-radio-button value="month1" @click="clickBtn"> a Month </a-radio-button>
    <a-radio-button value="month6" @click="clickBtn"> 6 Month </a-radio-button>
    <a-radio-button value="year" @click="clickBtn"> a Year </a-radio-button>
    <a-radio-button value="all" @click="clickBtn"> All </a-radio-button>
  </a-radio-group>
</template>

<script>
export default {
  props: {
    selectedBtn: {
      type: String,
      default: 'month1',
    },
  },
  methods: {
    clickBtn(e) {
      this.$emit('clickBtn', e.target.value);
    },
  }

}
</script>

<style>

</style>