<template>
  <div class="app">
    <!-- antD 컴퍼넌트 한글화 처리 -->
    <a-config-provider :locale="ko_KR">
      <!-- 레이아웃 컴퍼넌트 -->
      <router-view :key="$route.fullPath" />
    </a-config-provider>
  </div>
</template>

<script>
import ko_KR from 'ant-design-vue/lib/locale-provider/ko_KR';
import moment from 'moment';
import 'moment/locale/ko';

moment.locale('ko'); // antD 한글화 처리
export default {
  data() {
    return {
      ko_KR,
    };
  },
};
</script>

<style lang="scss">
@import 'assets/css/common.css';
@import 'assets/scss/layout';
@import 'assets/scss/style';
@import 'assets/scss/common';
</style>
