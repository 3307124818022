<template>
  <a-layout id="layout">
    <AppLeft />
    <a-layout class="min-wid-900">
      <AppTopBar></AppTopBar>
      <a-layout-content class="app-contents">
        <router-view></router-view>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
import AppLeft from '@/components/common/AppLeftMenu.vue';
import AppTopBar from '@/components/common/AppTopBar.vue';

export default {
  components: { AppLeft, AppTopBar },
};
</script>
