<template>
  <!-- 버튼이 포함된 경우에만 class명 include-btn 추가 -->
  <div class="date-comp" v-bind:class="{ includeBtn: isUseBtn }">
    <a-button type="primary" ghost class="cube mar-ri10" v-if="isUseBtn" @click="clickLeft">
      <a-icon type="left"
    /></a-button>
    <a-date-picker v-model="propsCalendar" :format="dateFormat" @change="calendarEvent($event)"></a-date-picker>
    <a-button type="primary" ghost class="cube mar-le10" v-if="isUseBtn" @click="clickRight"
      ><a-icon type="right"
    /></a-button>
  </div>
</template>

<script>
export default {
  props: {
    calendar: null,
    isUseBtn: false,
  },
  data() {
    return {
      todayNum: 0,
      dateFormat: 'YYYY-MM-DD',
      propsCalendar: this.calendar,
    };
  },
  watch: {
    calendar() {
      this.propsCalendar = this.calendar;
      this.calDiff(this.calendar);
    },
  },
  mounted() {
    this.makeToday();
  },
  methods: {
    calendarEvent(e) {
      if (e === '' || e === null) return;

      const tDate = this.$moment(e._d).format(this.dateFormat);
      this.calDiff(tDate);

      // 현재의 날짜 gap계산 todayNum
      this.$emit('dateChange', tDate);
    },
    calDiff(tDate) {
      let today = this.$helper.caldate(0);
      today = this.$helper.dateFormater(today, '-');
      const gap = this.$helper.dateDiff(today, tDate);
      // console.log(tDate, '', today, gap);
      this.todayNum = gap;
    },
    makeToday() {
      let today = this.$helper.caldate(this.todayNum);
      today = this.$helper.dateFormater(today, '-');
      today = this.$moment(today).format(this.dateFormat);
      // console.log(today);
      // this.propsCalendar = today;
      this.$emit('dateChange', this.propsCalendar);
    },
    clickLeft() {
      this.todayNum--;
      this.makeToday();
    },
    clickRight() {
      if (this.todayNum === 0) return;
      this.todayNum++;
      this.makeToday();
    },
  },
};
</script>

<style lang="scss">
.date-comp {
  &.includeBtn {
    .ant-calendar-picker {
      width: calc(100% - 120px) !important;
    }
    .cube {
      width: 50px !important;
    }
  }
}
</style>