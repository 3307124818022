import axios from "axios";

console.log(process.env.NODE_ENV);
export default process.env.NODE_ENV == "production"
  ? axios.create({
    //production 환경 (AWS EC2)
    baseURL: "https://was.astellaskakaochannel.com",
    // baseURL: "http://13.209.20.178:4005",
  })
  : axios.create({
    //development 환경
    baseURL: "http://localhost:4005",
    // baseURL: "https://was.astellaskakaochannel.com",
  });