<template>
  <div class="page login">
    <LoginForm></LoginForm>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LoginForm from '@/components/LoginForm.vue';

export default {
  components: {
    LoginForm,
  },
  computed: {
    ...mapGetters(['isAuthorized']),
  },
  watch: {
    // isLogin() {
    //   console.log('isLogin');
    //   if (this.state.isAuthorized) {
    //     this.$router.push('/userInfo');
    //   }
    // },
  },
};
</script>

<style lang="scss">
@import '~@/assets/scss/common';
.login {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
</style>
