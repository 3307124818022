const moment = require("moment");

exports.contentName = () => {
    return [
        // { kakaoChannel: '프로그랍-Autoimmune봇', value: '프로그랍AI 컨텐츠', label: '프로그랍-Autoimmune' },
        { kakaoChannel: '프로그랍-Autoimmune봇', value: '프로그랍AI 컨텐츠(RA)', label: '프로그랍-Autoimmune - RA' },
        { kakaoChannel: '프로그랍-Autoimmune봇', value: '프로그랍AI 컨텐츠(LN)', label: '프로그랍-Autoimmune - LN' },
        { kakaoChannel: 'transplantation봇', value: '프로그랍TX 컨텐츠', label: '프로그랍-transplantation' },
        { kakaoChannel: 'transplantation봇', value: '아드바그랍 제품정보', label: '프로그랍-transplantation' },
        { kakaoChannel: '하루날베타미가봇', value: '하루날디 컨텐츠', label: '하루날디베타미가 뉴스 – 하루날디 컨텐츠' },
        { kakaoChannel: '하루날베타미가봇', value: '베타미가 컨텐츠', label: '하루날디베타미가 뉴스 – 베타미가 컨텐츠' },
        { kakaoChannel: '하루날베타미가봇', value: '비뇨의학과 학회일정', label: '하루날디베타미가 뉴스 – 비뇨의학과 학회일정' }
    ]
};

exports.kakaoChannelAll = () => {
    let reData = [
        { value: '', label: '[전체]' },
        { value: '프로그랍-Autoimmune봇', label: '프로그랍-Autoimmune' },
        { value: 'transplantation봇', label: '프로그랍-Transplantation' },
        { value: '하루날베타미가봇', label: '하루날베타미가 뉴스' },
        { value: '엑스탄디봇', label: '엑스탄디 뉴스' },
    ]

    return reData;
};

exports.isHcpTypeItems = () => {
    let reData = [
        { value: '', label: '[전체]' },
        { value: 'Y', label: '승인' },
        // { value: 'N', label: '반려' },   //반려 시 삭제되므로 선택결과가 없어 삭제
        { value: 'W', label: '대기' },
    ]

    return reData;
};

exports.friendsCardNewsAll = () => {
    let reData = [
        { value: 'test01', label: '카드뉴스(#1:발송일자11/10일)' },
    ]

    return reData;
};

exports.kakaoChannel = () => {
    let reData = [
        { value: '프로그랍-Autoimmune봇', label: '프로그랍-Autoimmune' },
        { value: 'transplantation봇', label: '프로그랍-Transplantation' },
        { value: '하루날베타미가봇', label: '하루날베타미가 뉴스' },
        { value: '엑스탄디봇', label: '엑스탄디 뉴스' },
    ]

    return reData;
};

exports.userType = () => {
    let reData = [
        { value: 'USER', label: 'USER' },
        { value: 'MANAGER', label: 'MANAGER' },
        { value: 'ADMIN', label: 'ADMIN' },
    ]

    return reData;
};

exports.backgroundColorColumn = () => {
    let reData = [
        '#ff6484',
        '#6baed6',
        '#fd8d3c',
        '#74c476',
        '#9e9ac8',
        '#fdbe85',
        '#3182bd',
        '#31a354',
        '#756bb1',
        '#fdae6b',
    ]
    return reData;
};

exports.defaultChartData = () => {
    let reData = [
        {
            label: 'Visitors',
            backgroundColor: '#f87979',
            data: [],
        },
    ]
    return reData;
};

exports.requiredFieldAlertMessage = () => " 필수 입력입니다";

exports.validateEmail = (email) => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

exports.userInfoTableColumns = () => {
    let reData = [
        {
            key: 'no',
            title: 'No',
            dataIndex: 'no',
            width: 50,
            scopedSlots: { customRender: 'no' }
        },
        // {
        //   key: 'useYNLabel',
        //   title: '로그인허용/차단',
        //   dataIndex: 'useYNLabel',
        //   scopedSlots: { customRender: 'useYNLabel' }
        // },
        {
            key: 'kakaoChannelLbl', //@m.oh 각 사용자별 가입 카카오 채널 데이터 연결 필요
            title: '가입채널',
            dataIndex: 'kakaoChannelLbl',
            width: 160,
            align: 'left',
            ellipsis: true,
            scopedSlots: { customRender: 'kakaoChannelLbl' }
        },
        {
            key: 'name',
            title: '이름',
            dataIndex: 'name',
            width: 80,
            ellipsis: true,
            scopedSlots: { customRender: 'name' }
        },
        {
            key: 'mobile',
            title: '휴대전화',
            dataIndex: 'mobile',
            width: 140,
            scopedSlots: { customRender: 'mobile' }
        },
        {
            key: 'email',
            title: '이메일',
            dataIndex: 'email',
            ellipsis: true,
            scopedSlots: { customRender: 'email' }
        },
        {
            key: 'hospital',
            title: '병원명',
            dataIndex: 'hospital',
            ellipsis: true,
            scopedSlots: { customRender: 'hospital' }
        },
        {
            key: 'hospitalAddress',
            title: '병원주소',
            dataIndex: 'hospitalAddress',
            ellipsis: true,
            scopedSlots: { customRender: 'hospitalAddress' }
        },
        {
            key: 'regDateLabel',
            title: '회원가입일',
            dataIndex: 'regDateLabel',
            width: 200,
            scopedSlots: { customRender: 'regDateLabel' }
        },
        {
            key: 'isHcpLabel',
            title: '승인여부',
            dataIndex: 'isHcpLabel',
            width: 80,
            scopedSlots: { customRender: 'isHcpLabel' }
        },
        {
            key: 'MR',
            title: '담당자',
            dataIndex: 'MR',
            width: 100,
            scopedSlots: { customRender: 'MR' }
        },
    ];
    return reData;
};

/**
 *
 * @param {moment} startDate
 * @param {moment} endDate
 * @returns startDate와 endDate 사이 날짜의 배열 리턴
 */
exports.setDateLabel = (startDate, endDate) => {
    if (startDate.format('YYYYMMDD') === endDate.format('YYYYMMDD')) {
        let time = [];
        for (let i = 0; i <= 23; i++) {
            let hour = i < 10 ? '0' + i : i;
            time.push(hour + ':00');
        }
        return time;
    } else {
        const days = endDate.diff(startDate, 'days');
        const dayRange = Array.from({ length: days + 1 }, (_, i) => i);
        const dateArray = dayRange.map((day) => {
            const date = moment(startDate).add(day, 'days');
            return date.format('YYYY-MM-DD');
        });
        // console.log(dateArray);
        return dateArray;
    }
};

/**
 * @param {Array} dataList 응답받은 전체 로그 (visitLog)
 * @param {string} dateType today: HH:mm, 나머지: YYYY-MM-DD
 */
exports.setVisitDataset = (dataList, dateType) => {
    const mapObj = new Map();

    dataList.forEach((item) => {
        const date = moment(item.createdAt);
        if (!date.isValid()) throw new Error('올바른 date 값이 아닙니다.');

        const key = date.format(dateType);
        if (mapObj.has(key)) {
            mapObj.set(key, mapObj.get(key) + 1);
        } else {
            mapObj.set(key, 1);
        }
    });
    console.log(mapObj);
    return mapObj;
};

// 버튼클릭로그 테이블
exports.menuLogViewColumn = () => {
    let reData = [
        // {
        //     key: 'no',
        //     title: 'No',
        //     dataIndex: 'no',
        //     width: 50,
        //     scopedSlots: { customRender: 'no' }
        // },
        // {
        //     key: 'regDateLabel',
        //     title: '조회일시',
        //     dataIndex: 'regDateLabel',
        //     width: 200,
        //     scopedSlots: { customRender: 'regDateLabel' }
        // },
        {
            key: 'channelNameLbl',
            title: '가입채널',
            dataIndex: 'channelNameLbl',
            width: 180,
            ellipsis: true,
            scopedSlots: { customRender: 'channelNameLbl' }
        },
        {
            key: 'utterance',
            title: '퀵메뉴명',
            dataIndex: 'utterance',
            align: 'left',
            ellipsis: true,
            scopedSlots: { customRender: 'utterance' }
        },
        {
            key: 'page',
            title: '클릭수',
            dataIndex: 'page',
            width: 50,
            ellipsis: true,
            scopedSlots: { customRender: 'page' }
        },
        // {
        //     key: 'userName',
        //     title: '사용자명',
        //     dataIndex: 'userName',
        //     width: 80,
        //     ellipsis: true,
        //     scopedSlots: { customRender: 'userName' }
        // },
    ];
    return reData;
};

exports.menuLogViewDetailColumn = () => {
    let reData = [
        // {
        //     key: 'no',
        //     title: 'No',
        //     dataIndex: 'no',
        //     width: 50,
        //     scopedSlots: { customRender: 'no' }
        // },
        {
            key: 'regDateLabel',
            title: '조회일시',
            dataIndex: 'regDateLabel',
            width: 200,
            scopedSlots: { customRender: 'regDateLabel' }
        },
        {
            key: 'userName',
            title: '사용자명',
            dataIndex: 'userName',
            width: 80,
            ellipsis: true,
            scopedSlots: { customRender: 'userName' }
        },
        {
            key: 'hospital',
            title: '병원병',
            dataIndex: 'hospital',
            width: 100,
            ellipsis: true,
            scopedSlots: { customRender: 'hospital' }
        },
        {
            key: 'mobile',
            title: '휴대폰',
            dataIndex: 'mobile',
            width: 120,
            ellipsis: true,
            scopedSlots: { customRender: 'mobile' }
        },
        {
            key: 'email',
            title: '이메일',
            dataIndex: 'email',
            ellipsis: true,
            scopedSlots: { customRender: 'email' }
        },
        {
            key: 'MR',
            title: '담당자',
            dataIndex: 'MR',
            width: 100,
            scopedSlots: { customRender: 'MR' }
        },
    ];
    return reData;
};

// 콘텐츠별 통계 버튼클릭로그 테이블
exports.logViewColumn = () => {
    let reData = [
        // {
        //     key: 'no',
        //     title: 'No',
        //     dataIndex: 'no',
        //     width: 50,
        //     scopedSlots: { customRender: 'no' }
        // },
        // {
        //     key: 'regDateLabel',
        //     title: '조회일시',
        //     dataIndex: 'regDateLabel',
        //     width: 200,
        //     scopedSlots: { customRender: 'regDateLabel' }
        // },
        {
            key: 'channelNameLbl',
            title: '가입채널',
            dataIndex: 'channelNameLbl',
            width: 180,
            ellipsis: true,
            scopedSlots: { customRender: 'channelNameLbl' }
        },
        {
            key: 'content',
            title: '콘텐츠명',
            dataIndex: 'content',
            align: 'left',
            ellipsis: true,
            scopedSlots: { customRender: 'content' }
        },
        {
            key: 'page',
            title: '페이지',
            dataIndex: 'page',
            width: 100,
            ellipsis: true,
            scopedSlots: { customRender: 'page' }
        },
        {
            key: 'link1Count',
            title: '링크1클릭',
            dataIndex: 'link1Count',
            width: 100,
            ellipsis: true,
            scopedSlots: { customRender: 'link1Count' }
        },
        {
            key: 'link2Count',
            title: '링크2클릭',
            dataIndex: 'link2Count',
            width: 100,
            ellipsis: true,
            scopedSlots: { customRender: 'link2Count' }
        },
        {
            key: 'pageClickNum',
            title: '클릭수',
            dataIndex: 'pageClickNum',
            width: 80,
            ellipsis: true,
            scopedSlots: { customRender: 'pageClickNum' }
        },
        // {
        //     key: 'userName',
        //     title: '사용자명',
        //     dataIndex: 'userName',
        //     width: 80,
        //     ellipsis: true,
        //     scopedSlots: { customRender: 'userName' }
        // },
    ];
    return reData;
};

// 친구톡 카드뉴스 통계 로그 테이블
exports.friendsLogColumn = () => {
    let reData = [
        {
            key: 'no',
            title: 'No',
            dataIndex: 'no',
            width: 50,
            scopedSlots: { customRender: 'no' }
        },
        {
            key: 'channelNameLbl',
            title: '가입채널',
            dataIndex: 'channelNameLbl',
            width: 180,
            ellipsis: true,
            scopedSlots: { customRender: 'channelNameLbl' }
        },
        {
            key: 'cardNews',
            title: '카드뉴스명',
            dataIndex: 'cardNews',
            width: 100,
            scopedSlots: { customRender: 'cardNews' }
        },
        // {
        //     key: 'regDateLabel',
        //     title: '조회일시',
        //     dataIndex: 'regDateLabel',
        //     width: 200,
        //     scopedSlots: { customRender: 'regDateLabel' }
        // },
        // {
        //     key: 'content',
        //     title: '콘텐츠명',
        //     dataIndex: 'content',
        //     ellipsis: true,
        //     scopedSlots: { customRender: 'content' }
        // },
        // {
        //     key: 'page',
        //     title: '페이지',
        //     dataIndex: 'page',
        //     width: 50,
        //     ellipsis: true,
        //     scopedSlots: { customRender: 'page' }
        // },
        {
            key: 'page',
            title: '버튼클릭수(인증한횟수)',
            dataIndex: 'page',
            width: 150,
            ellipsis: true,
            scopedSlots: { customRender: 'page' }
        },
        {
            key: 'userName',
            title: '사용자명',
            dataIndex: 'userName',
            width: 80,
            ellipsis: true,
            scopedSlots: { customRender: 'userName' }
        },
    ];
    return reData;
};
