<template>
  <a-range-picker :allowClear="false" :format="dateFormat" :value="propsDate" @change="changeDate">
    <a-icon slot="suffixIcon" type="calendar" />
  </a-range-picker>
</template>

<script>
export default {
  props: {
    dateFormat: {
      type: String,
      default: 'YYYY-MM-DD',
    },
    propsDate: {
      type: Array,
      default: ()=> [],
    },
  },
  methods: {
    changeDate(e) {
      this.$emit('changeDate', e);
    },
  }
}
</script>

<style>

</style>