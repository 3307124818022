<template>
  <div>
    <a-modal v-model="visible" width="800px" centered :afterClose="afterClose">
      <!-- 버튼 -->
      <template slot="footer">
        <a-button key="submit" type="primary" @click="handleOk">저장</a-button>
        <a-button key="back" class="ant-btn-cancel" @click="handleCancel">취소</a-button>
      </template>

      <!-- body -->
      <div class="modal-content">
        <h2 class="modal-title">관리자등록</h2>
        <a-form ref="form">
          <a-list :split="true" class="titleTxt">
            <a-list-item>
              <a-col :span="4">관리자타입</a-col>
              <a-col :span="8">
                <SelectBox
                  :selectValue="userData.type"
                  :searchList="userTypeList"
                  @updateSelect="userTypeUpdateSelect"
                />
              </a-col>
              <a-col :span="4">*관리자아이디</a-col>
              <a-col :span="8">
                <a-input ref="refUserId" v-model="userData.userId" />
              </a-col>
            </a-list-item>
            <a-list-item>
              <a-col :span="4">*비밀번호</a-col>
              <a-col :span="8">
                <a-input ref="refPassword" type="password" v-model="userData.password" />
              </a-col>
              <a-col :span="4">*비밀번호확인</a-col>
              <a-col :span="8">
                <a-input ref="refRePassword" type="password" v-model="userData.rePassword" />
              </a-col>
            </a-list-item>
            <a-list-item>
              <a-col :span="4">*이름</a-col>
              <a-col :span="8">
                <a-input ref="refName" v-model="userData.name" />
              </a-col>
              <a-col :span="4">*전화번호</a-col>
              <a-col :span="8">
                <a-input ref="refMobile" v-model="userData.mobile" />
              </a-col>
            </a-list-item>
            <a-list-item>
              <a-col :span="4">성별</a-col>
              <a-col :span="20">
                <a-switch default-checked :checked="userData.gender === 'MALE'" @change="onChangeGender" />
                <span class="is-active spanGap">{{ userData.gender === 'FEMALE' ? '여자' : '남자' }}</span>
              </a-col>
            </a-list-item>
          </a-list>
        </a-form>
      </div>
    </a-modal>
  </div>
</template>
<script>
import Vue from 'vue';
import { mapActions } from 'vuex';
import SelectBox from '@/components/common/v2.0/SelectBox.vue';
const formatService = require('@/utils/format.js');

export default {
  components: {
    SelectBox,
  },
  data() {
    return {
      visible: false,
      userTypeList: formatService.userType(),
      requiredMsg: formatService.requiredFieldAlertMessage(),
      userData: {
        userId: '',
        password: '',
        rePassword: '',
        name: '본사관리자',
        type: 'ADMIN',
        mobile: '01012345678',
        gender: 'MALE',
        agree: 'Y',
        agree1: 'Y',
        agree2: 'Y',
      },
    };
  },
  methods: {
    showModal() {
      this.visible = true;

      setTimeout(() => {
        this.$refs.refUserId.focus();
      }, 500);
    },
    handleOk(e) {
      // 필수처리
      const D = this.userData;
      const RF = this.$refs;
      if (!this.$helper.requiredCheck(D.userId, RF.refUserId, `사용자아이디는 ${this.requiredMsg}`)) return;
      if (!this.$helper.requiredCheck(D.password, RF.refPassword, `비밀번호는 ${this.requiredMsg}`)) return;
      if (!this.$helper.requiredCheck(D.rePassword, RF.refRePassword, `비밀번호확인은 ${this.requiredMsg}`)) return;

      if (!formatService.validateEmail(D.userId)) {
        Vue.$toast.open({
          message: `관리자아이디는 이메일형식이어야 합니다`,
          type: 'error',
          duration: 1000,
          dismissible: true,
        });

        RF.refUserId.focus();
        return;
      }

      if (D.password.length <= 4 || D.password.length >= 20) {
        Vue.$toast.open({
          message: `비밀번호는 4글자 이상 20글자 이하 이어야 합니다`,
          type: 'error',
          duration: 1000,
          dismissible: true,
        });
        RF.refPassword.focus();
        return;
      }

      if (D.password !== D.rePassword) {
        Vue.$toast.open({
          message: `비밀번호가 일치해야 합니다`,
          type: 'error',
          duration: 1000,
          dismissible: true,
        });
        RF.refPassword.select();
        return;
      }

      if (!this.$helper.requiredCheck(D.name, RF.refName, `이름은 ${this.requiredMsg}`)) return;
      if (!this.$helper.requiredCheck(D.mobile, RF.refMobile, `휴대전화번호는 ${this.requiredMsg}`)) return;

      if (!confirm('관리자를 등록하시겠습니까?')) return;
      this.saveData();
    },
    handleCancel(e) {
      this.visible = false;
    },
    onChangeGender(checked) {
      this.userData.gender = checked ? 'MALE' : 'FEMALE';
    },
    userTypeUpdateSelect(e) {
      this.userData.type = e;
    },
    // 수정, 저장
    async saveData() {
      // console.log('', this);
      // return;
      this.signup(this).then(() => {
        this.visible = false;
      });
    },
    afterClose() {
      // this.$emit('completeSaveData', this.bbsData);
    },
    ...mapActions(['signup']),
  },
};
</script>
<style>
.spanGap {
  display: inline-block;
  text-align: center;
  width: 40px;
}
</style>