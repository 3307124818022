<template>
  <a-drawer
    :title="data && data.channelNameLbl + ` - ` + data.title + `(로그상세)`"
    placement="right"
    width="65%"
    :closable="true"
    :visible="visible"
    :after-visible-change="afterVisibleChange"
    @close="onClose"
  >
    <div>클릭수로그</div>
    <Table :data="data && data.data" :columns="tableListColumns" />

    <!-- 엑셀 -->
    <xlsxBtn @clickXlsx="clickXlsxHandler('상세로그')" />

    <div v-if="data && data.endPoint === 'contentsLogView'">
      <br /><br /><br /><br />
      <div>링크1클릭로그</div>
      <Table :data="link1TableData" :columns="tableListColumns" />

      <xlsxBtn @clickXlsx="clickXlsxHandler('링크1클릭로그')" />

      <br /><br /><br /><br />
      <div>링크2클릭로그</div>
      <Table :data="link2TableData" :columns="tableListColumns" />

      <xlsxBtn @clickXlsx="clickXlsxHandler('링크2클릭로그')" />
    </div>
  </a-drawer>
</template>
<script>
import Table from '@/components/common/adminForm/Table.vue';
import xlsxBtn from '@/components/common/adminForm/XlsxBtn.vue';
const formatService = require('@/utils/format.js');

import { mapActions, mapState } from 'vuex';
export default {
  components: {
    Table,
    xlsxBtn,
  },
  data() {
    return {
      data: null,
      visible: false,
      confirmLoading: false,
      tableListColumns: formatService.menuLogViewDetailColumn(),
      link1TableData: null,
      link2TableData: null,
    };
  },
  methods: {
    _showModal(e) {
      this.data = e;
      console.log('', e);

      this.setXlsxData(e.data);
      this.visible = true;

      if (e.endPoint === 'contentsLogView') {
        this.getLinkClickInfo(e);
      }
    },
    // 링크클릭정보
    getLinkClickInfo(e) {
      this.query.link = 'link1';
      this.query.page = e.queryPage;
      this.query.channelNameLbl = e.channelNameLbl;
      this.query.content = e.content;
      // console.log('', this.query);
      // return;
      this.getLinkLogs(this.query).then((resP) => {
        let res = resP.data;
        if (0 < res.length) {
          res = res.map((item, index) => ({
            ...item,
            regDateLabel: this.$moment(item.createdAt).format('YYYY년 MM월DD일 a h:mm'),
          }));
        }
        this.setXlsxData2(res);
        this.link1TableData = res;
      });
      this.query.link = 'link2';
      this.getLinkLogs(this.query).then((resP) => {
        let res = resP.data;
        if (0 < res.length) {
          res = res.map((item, index) => ({
            ...item,
            regDateLabel: this.$moment(item.createdAt).format('YYYY년 MM월DD일 a h:mm'),
          }));
        }
        this.setXlsxData3(res);
        this.link2TableData = res;
      });
    },
    afterVisibleChange() {},
    onClose() {
      this.visible = false;
    },
    clickXlsxHandler(pageType) {
      let targetData = [];
      if (pageType === '상세로그') {
        targetData = this.xlsxData;
      }
      if (pageType === '링크1클릭로그') {
        targetData = this.xlsxData2;
      }
      if (pageType === '링크2클릭로그') {
        targetData = this.xlsxData3;
      }
      this.$helper.xlsxDownload(targetData, 'sheet1', pageType + '_');
    },
    // 상세로그데이터
    setXlsxData(list) {
      this.xlsxData = [];
      for (const item of list) {
        this.xlsxData.push({
          조회일시: item.regDateLabel,
          병원명: item.hospital,
          사용자명: item.userName,
          휴대폰: item.mobile,
          이메일: item.email,
          담당자: item.MR,
        });
      }
    },
    // 링크1클릭로그데이터
    setXlsxData2(list) {
      this.xlsxData2 = [];
      for (const item of list) {
        this.xlsxData2.push({
          조회일시: item.regDateLabel,
          병원명: item.hospital,
          사용자명: item.userName,
          휴대폰: item.mobile,
          이메일: item.email,
          담당자: item.MR,
        });
      }
    },
    // 링크3클릭로그데이터
    setXlsxData3(list) {
      this.xlsxData3 = [];
      for (const item of list) {
        this.xlsxData3.push({
          조회일시: item.regDateLabel,
          병원명: item.hospital,
          사용자명: item.userName,
          휴대폰: item.mobile,
          이메일: item.email,
          담당자: item.MR,
        });
      }
    },
    ...mapActions(['getLinkLogs']),
  },
  computed: {
    ...mapState(['query']),
  },
};
</script>