<template>
  <div>
    <a-form class="auth-form" @submit="handleSubmit">
      <a-button class="btn-next per100" type="primary" @click="handleSubmit">다날 본인 인증하기</a-button>
    </a-form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      phone: '01067403230',
      initialMerchantUid: `mid_${new Date().getTime()}`,
    };
  },
  methods: {
    handleSubmit(e) {
      const { IMP } = window;
      // IMP.init('imp10391932'); // 데모
      IMP.init('imp95109913'); // 프리딕트
      const data = {
        merchant_uid: this.initialMerchantUid,
      };
      console.log(data.merchant_uid); // kslee temp
      data.phone = this.phone;
      IMP.certification(data, this.callback);
    },
    async callback(response) {
      // 본인인증 종료 후 result 페이지로 이동
      const query = {
        ...response,
        type: 'certification',
      };
      console.log(query); // kslee temp
      /* 리턴값 query 형태 : kslee
        success: true
        imp_uid: "imp_604676069370" // 사용자 추가정보 요청 키 
        merchant_uid: "mid_1649837662980"
        error_code: null
        error_msg: null
        pg_provider: "danal"
        pg_type: "certification"
        type: "certification"
      //*/
      if (query.success === false) {
        // 인증실패시 : kslee
        console.log('인증실패 : ', query);
        this.$router.push({ path: '/auth_kr' });
        return;
      } else if (query.success === true) {
        console.log(query.success, query.imp_uid);
        // this.$emit('clickFromInfo', 1); // kslee temp : 임시 페이지 이동 이벤트

        // const R = await userInfoAfterPass(query.imp_uid);
        // const res = R.data;
        // // youngme: comment phone을 임시로 input에 입력한 값을 넣어줌.
        // res.phone = this.phone;
        // res.unique_key = res.unique_key.replaceAll(' ', '');
        // console.log(res);

        this.$emit('successPassAuth', res);
        /* 넘어오는 데이터 샘플
          birthday: 1988-12-30
          gender: "m"
          name: "홍길동"
          phone: "01012345678" // 추가 심사 후 확인 가능
          unique_in_site: "unique_in_site"
          unique_key: "unique_key"
        // */
      }
    },
  },
};
</script>
<style lang="">
</style>