<template>
  <a-row>
    <a-col :span="11">
      <DateRangePicker :propsDate="propsDate" @changeDate="changeDate" />
    </a-col>
    <a-col :span="12">
      <DateRadioBtn :selectedBtn="selectedBtn" @clickBtn="clickDateBtn" />
    </a-col>
  </a-row>
</template>

<script>
import DateRangePicker from '@/components/common/DateRangePicker.vue';
import DateRadioBtn from '@/components/common/DateRadioBtn.vue';
export default {
  components: {
    DateRangePicker,
    DateRadioBtn,
  },
  // 컴포넌트 사용법: input은 prop (propsDate, selectedBtn)으로 output (changeDate, clickDateBtn)은 event로 받아서 사용한다.
  props: {
    propsDate: {
      type: Array,
      default: () => [],
    },
    selectedBtn: {
      type: String,
      default: 'month1',
    },
  },
  methods: {
    changeDate(e) {
      this.$emit('clickDate', e);
      this.$emit('changeDate', e);
      //   this.propsDate = e;
    },
    clickDateBtn(e) {
      this.$emit('clickDateBtn', e);
      this.changeBtnToDay(e);
      //   this.selectedBtn = e;
    },
    changeBtnToDay(e) {
      const today = this.$moment();
      let stDay = this.$moment();
      switch (e) {
        case 'today':
          break;
        case 'week':
          stDay = this.$moment().subtract(7, 'days');
          break;
        case 'month1':
          stDay = stDay.clone().subtract(1, 'months');
          break;
        case 'month6':
          stDay = stDay.clone().subtract(6, 'months');
          break;
        case 'year':
          stDay = stDay.clone().subtract(1, 'years');
          break;
        case 'all':
          stDay = stDay.clone().subtract(20, 'years');
          break;
      }
      const d = {
        fromDay: stDay,
        toDay: today,
      };
      //   console.log('changeBtnToDay', d);
      //   this.propsDate = [d.fromDay, d.toDay];
      this.$emit('changeDate', [stDay, today]);
    },
  },
};
</script>

<style>
</style>