<template>
  <div class="mincontainer single">
    <div class="table-top-ui">
      <!-- topUI Date -->
      <SearchDate
        :propsDate="propsDate"
        :selectedBtn="selectedBtn"
        @changeDate="changeDate"
        @clickDateBtn="clickDateBtn"
      />
    </div>

    <!-- 채널선택 콤보 -->
    <SelectBox :selectValue="selectChannel" :searchList="searchList" @updateSelect="kakaoChannelUpdateSelect" />
      HCP인증수 : {{ isHcpNum && isHcpNum[0].isHcpNum }} /
      HCP인증 대기수 : {{ isHcpWNum && isHcpWNum[0].isHcpWNum }}
    <br />
    <br />
    <!-- chart -->
    <LineChartGenerator :chartData="chartData" :options="chartOptions" :height="150" />
    <br />

    <!-- 상세 로그 list -->
    <Table v-if="subTableViewOpt" :data="originData" :columns="tableList" @clickRow="clickRowHandler" />

    <!-- 상세보기 페이지 -->
    <logDetail ref="refLogDetail" />

    <!-- 엑셀 -->
    <xlsxBtn @clickXlsx="clickXlsxHandler" />
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import SearchDate from '@/components/common/adminForm/SearchDate.vue';
import SelectBox from '@/components/common/v2.0/SelectBox.vue';
import topUI from '@/components/common/adminForm/SearchTopUIComp.vue';
import xlsxBtn from '@/components/common/adminForm/XlsxBtn.vue';
import LineChartGenerator from '@/components/common/chart/LineChartGenerator.vue';
import Table from '@/components/common/adminForm/Table.vue';
import logDetail from './LogDetail.vue';

export default {
  components: {
    SelectBox,
    SearchDate,
    LineChartGenerator,
    Table,
    logDetail,
    xlsxBtn,
  },
  props: {
    originData: {
      type: [Object, Array],
    },
    chartData: {
      type: [Object],
    },
    searchList: {
      type: [Object, Array],
    },
    tableList: {
      type: [Object, Array],
    },
    selectChannel: {
      type: String,
    },
    subTableViewOpt: {
      type: Boolean,
      default: false,
    },
    isHcpNum: {
      type: [Object, Array],
    },
    isHcpWNum: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      List: null,
      selectedBtn: 'month1',
      propsDate: [this.$moment().subtract(30, 'days'), this.$moment()],
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
      // logViewColumns: formatService.logViewColumn(),
    };
  },
  methods: {
    clickDateBtn(e) {
      this.selectedBtn = e;
    },
    changeDate(e) {
      this.propsDate = e;
      this.$emit('changeDate', e);
    },
    kakaoChannelUpdateSelect(e) {
      this.$emit('changeKakaoChannel', e);
    },
    clickRowHandler(e) {
      this.$refs.refLogDetail._showModal(e);
    },
    clickXlsxHandler() {
      this.$emit('clickXlsx');
    },
  },
  computed: {
    ...mapState(['logs']),
  },
};
</script>