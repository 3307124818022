/**
 * Input Data 정의 labels: 배열, datasets: 배열 내부 객체 검토 필요
 */
export class ChartData {
  constructor({ labels, datasets }) {
    if (!Array.isArray(labels)) throw new Error('labels가 잘못 입력되었습니다.');
    if (labels.some(item => typeof item !== 'string')) throw new Error('labels가 잘못 입력되었습니다.');
    this.labels = labels;
    if (!Array.isArray(datasets)) throw new Error('datasets가 잘못 입력되었습니다.');
    if (
      datasets.some(
        item => !item.hasOwnProperty('label') || !item.hasOwnProperty('backgroundColor') || !item.hasOwnProperty('data')
      )
    )
      throw new Error('datasets가 잘못 입력되었습니다.');
    this.datasets = datasets;
  }
  updateLabels(labels) {
    if (!Array.isArray(labels)) throw new Error('labels가 잘못 입력되었습니다.');
    if (labels.some(item => typeof item !== 'string')) throw new Error('labels가 잘못 입력되었습니다.');
    this.labels = labels;
  }
  /**
   *
   * @param {Array} datasets
   */
  updateDatasets(datasets) {
    if (
      datasets.some(
        item => !item.hasOwnProperty('label') || !item.hasOwnProperty('backgroundColor') || !item.hasOwnProperty('data')
      )
    )
      throw new Error('datasets가 잘못 입력되었습니다.');
    this.datasets = datasets;
  }
}
