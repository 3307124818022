<template>
  <div>
    <div class="label-group">Result : {{ data && data.length }}</div>
    <div class="right">
      <a-space>
        <a-button v-if="btnIsShow" type="primary" @click="clickEditorBtn">{{ btnLabel }}</a-button>
        <a-button type="primary" @click="clickXlsxBtn">Excel Download</a-button>
        <a-button type="primary" ghost class="cube mar-ri10" @click="clickReload"><a-icon type="reload" /></a-button>
      </a-space>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      default: null,
      type: Array,
    },
    btnIsShow: {
      default: true,
      type: Boolean,
    },
    btnLabel: {
      default: '등록',
      type: String,
    },
  },
  methods: {
    clickReload() {
      this.$emit('clickReload');
    },
    clickEditorBtn() {
      this.$emit('clickEditorBtn');
    },
    clickXlsxBtn() {
      this.$emit('clickXlsxBtn');
    },
    clickCreateBtn() {
      this.$emit('clickCreateBtn');
    },
  },
};
</script>
<style lang="">
</style>