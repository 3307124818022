<template>
  <div>
    <a-table
      :columns="columns"
      :data-source="data"
      :customRow="RowClick"
      class="clickable-row"
      :rowKey="
        (record, index) => {
          return index;
        }
      "
      bordered
    >
      <!-- <a slot="carNum" slot-scope="text, record" @click="RowClick(record)">{{ record.carNum }}</a> -->
      <!-- <a slot="editorOpen" slot-scope="text, record" @click="RowClick(record)"><a-icon type="car" /></a> -->
    </a-table>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: [Object, Array],
    },
    columns: {
      type: [Object, Array],
    },
    customColumn: {
      default: '',
      type: String,
    },
  },
  methods: {
    RowClick(record) {
      return {
        on: {
          click: () => {
            this.$emit('clickRow', record);
          },
        },
      };
    },
  },
};
</script>