import {
  SET_ACCESS_TOKEN,
  SET_MY_INFO,
  DESTROY_ACCESS_TOKEN,
  DESTROY_MY_INFO,
  SET_MEMBERS,
  SET_LOGS,
  SET_TO_DAY,
  SET_FROM_DAY,
  SET_QUERY
} from "./mutations-types";
import api from "@/api";
import Cookies from "js-cookie";

export default {
  [SET_ACCESS_TOKEN](state, accessToken) {
    if (accessToken) {
      state.accessToken = accessToken;
      api.defaults.headers.common["authorization"] = `Bearer ${accessToken}`;

      Cookies.set("accessToken", accessToken, { expires: 100000 });
    }
  },
  [SET_MY_INFO](state, me) {
    if (me) {
      state.me = me;
    }
  },
  [DESTROY_ACCESS_TOKEN](state) {
    state.accessToken = "";
    delete api.defaults.headers.common["authorization"];
    Cookies.remove("accessToken");
  },
  [DESTROY_MY_INFO](state) {
    state.me = null;
  },
  [SET_MEMBERS](state, members) {
    if (members) {
      state.members = members;
    }
  },
  [SET_LOGS](state, logs) {
    if (logs) {
      state.logs = logs;
    }
  },
  [SET_FROM_DAY](state, value) {
    if (value) {
      state.fromDay = value;
    }
  },
  [SET_TO_DAY](state, value) {
    if (value) {
      state.toDay = value;
    }
  },
  [SET_QUERY](state, value) {
    if (value) {
      state.query = value;
    }
  },
};
