<template>
  <a-layout-header class="top-menu">
    <!-- <a-icon class="trigger" type="menu" @click="toggleCollapsed" /> -->
    <div class="top-right">
      <span class="current-time">
        기준 : {{ $store.state.me && $moment($store.state.me.lastLoginDate).format('dddd YYYY-MM-DD, LT') }}
      </span>
      <!-- <a-icon type="setting" theme="filled" /> -->
      <a-divider type="vertical" />
      <a-dropdown :trigger="['click']">
        <span class="ant-dropdown-link" @click="(e) => e.preventDefault()">
          <a-avatar size="small" icon="user" />
          <span v-if="isUserLogin">({{ $store.state.me && $store.state.me.name }})</span>
          <!-- <a-icon type="down" /> -->
        </span>
      </a-dropdown>
      <a-divider type="vertical" />
      <a href="javascript;" class="logout-button" @click="logoutUser"><a-icon type="logout" /></a>
    </div>
  </a-layout-header>
</template>

<script>
// import { eventBus } from '@/main';

export default {
  data() {
    return {
      collapsed: false,
    };
  },
  computed: {
    isUserLogin() {
      return this.$store.getters.isAuthorized;
    },
    logoLink() {
      return this.$store.getters.isAuthorized ? '/member' : '/login';
    },
  },
  methods: {
    menuClick() {
      this.$emit('menuClick', 'test');
    },
    handleChange(value) {
      console.log(`selected ${value}`);
    },
    toggleCollapsed() {
      this.collapsed = !this.collapsed;
      // eventBus.$emit('ToggleCollapsed');
    },
    toggleLanguage(lang) {
      this.$i18n.locale = lang;
      // this.$cookies.set('locale', lang, '4y');
      console.log(document.cookie);
    },
    async logoutUser() {
      if (confirm('로그아웃 하시겠습니까?')) {
        await this.$store.dispatch('signOut').then(() => {
          this.$router.push('/login');
        });
      }
    },
  },
};
</script>

<style lang="scss">
.top-menu {
  padding: 15px;
}

.trigger {
  font-size: 18px;
  line-height: 50px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
  &:hover {
    color: $active-color;
  }
}
.top-menu.ant-layout-header {
  background: #fff;
  padding: 0;
  font-size: 12px;
  height: 50px;
  line-height: 50px;
  box-shadow: 0 0 10px 0 #e4e4e4;
  z-index: 100;
}
.top-right {
  float: right;
  padding: 0 15px;
  .current-time {
    margin-right: 10px;
  }
  .ant-avatar {
    margin-right: 10px;
  }
  .anticon {
    font-size: 14px;
    &.anticon-bell {
      font-size: 18px;
      vertical-align: middle;
    }
  }
  .ant-select {
    .ant-select-selection {
      border: none;
      border-radius: 0;
      box-shadow: none;
      &:active,
      &:focus {
        box-shadow: none;
      }
      .ant-select-arrow {
        color: $primary-color;
      }
    }
  }
}
</style>