<template>
  <div class="mincontainer single">
    <div class="table-top-ui">
      <!-- topUI Date -->
      <SearchDate
        :propsDate="propsDate"
        :selectedBtn="selectedBtn"
        @changeDate="changeDate"
        @clickDateBtn="clickDateBtn"
      />
    </div>

    <!-- 채널선택 콤보 -->
    <SelectBox :selectValue="selectCardNews" :searchList="searchList" @updateSelect="cardNewsUpdateSelect" />
    HCP인증수 : {{ isHcpNum && isHcpNum[0].isHcpNum }}
    <br />
    <br />
    <!-- chart -->
    <LineChartGenerator :chartData="chartData" :options="chartOptions" :height="150" />
    <br />

    <!-- 상세 로그 list -->
    <Table v-if="subTableViewOpt" :data="originData" :columns="logViewColumns" />
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import SearchDate from '@/components/common/adminForm/SearchDate.vue';
import SelectBox from '@/components/common/v2.0/SelectBox.vue';
import topUI from '@/components/common/adminForm/SearchTopUIComp.vue';
import LineChartGenerator from '@/components/common/chart/LineChartGenerator.vue';
import Table from '@/components/common/adminForm/Table.vue';

const formatService = require('@/utils/format.js');

export default {
  components: {
    SelectBox,
    SearchDate,
    LineChartGenerator,
    Table,
  },
  props: {
    originData: {
      type: [Object, Array],
    },
    chartData: {
      type: [Object],
    },
    searchList: {
      type: [Object, Array],
    },
    selectCardNews: {
      type: String,
    },
    subTableViewOpt: {
      type: Boolean,
      default: false,
    },
    isHcpNum: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      List: null,
      selectedBtn: 'month1',
      propsDate: [this.$moment().subtract(30, 'days'), this.$moment()],
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
      logViewColumns: formatService.friendsLogColumn(),
    };
  },
  methods: {
    clickDateBtn(e) {
      this.selectedBtn = e;
    },
    changeDate(e) {
      this.propsDate = e;
      this.$emit('changeDate', e);
    },
    cardNewsUpdateSelect(e) {
      this.$emit('changeCardNews', e);
    },
  },
  computed: {
    ...mapState(['logs']),
  },
};
</script>