<template>
  <div class="production-sales-history page">
    <h1 class="page-header">회원관리</h1>

    <div class="container single">
      <div class="table-top-ui">
        <!-- topUI Date -->
        <SearchDate
          :propsDate="propsDate"
          :selectedBtn="selectedBtn"
          @changeDate="changeDate"
          @clickDate="clickDate"
          @clickDateBtn="clickDateBtn"
        />
        <!-- topUI -->
        <topUI
          placeholder="회원명"
          :propsSearchWorld="searchValue"
          @changeText="changeText"
          @changeSearchWordComplete="changeSearchWordComplete"
        />

        <!-- 채널선택 콤보 -->
        <SelectBox :selectValue="selectChannel" :searchList="kakaoChannel" @updateSelect="kakaoChannelUpdateSelect" />
        &nbsp;
        <SelectBox 
          :selectValue="selectIsHcpType" 
          :searchList="isHcpTypeItems" 
          @updateSelect="isHcpTypeUpdateSelect" />

        <!-- total -->
        <TopTotal
          :btnIsShow="false"
          :data="List"
          @clickXlsxBtn="xlsxSave"
          @clickEditorBtn="clickEditorBtn"
          @clickReload="clickReload"
        />
      </div>

      <!-- list -->
      <Table :data="List" :columns="userInfoTable" @clickRow="tableClickRow" />

      <!-- 편집창 -->
      <UserInfoEditor ref="userInfoEditorPage" @completeSaveData="userEditorCompleteSaveData" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import SearchDate from '@/components/common/adminForm/SearchDate.vue';
import topUI from '@/components/common/adminForm/SearchTopUIComp.vue';
import TopTotal from '@/components/common/adminForm/TopTotal.vue';
import Table from '@/components/common/adminForm/Table.vue';
import SelectBox from '@/components/common/v2.0/SelectBox.vue';
import UserInfoEditor from './UserInfoEditor.vue';

const formatService = require('@/utils/format.js');

export default {
  components: {
    topUI,
    Table,
    TopTotal,
    SelectBox,
    SearchDate,
    UserInfoEditor,
  },
  data() {
    return {
      kakaoChannel: formatService.kakaoChannelAll(),
      userInfoTable: formatService.userInfoTableColumns(),
      List: null,
      xlsxData: [],
      selectChannel: '',
      selectIsHcpType: '',
      isHcpTypeItems: formatService.isHcpTypeItems(),
      selectedBtn: 'month1',
      propsDate: [this.$moment().subtract(30, 'days'), this.$moment()],
      searchValue: '',
    };
  },
  async created() {
    this.getList();
  },
  methods: {
    async getList() {
      this.fromDay = this.propsDate[0].format('YYYYMMDD');
      this.toDay = this.propsDate[1].format('YYYYMMDD');
      await this.getMembers(this).then(() => {
        // console.log('', this.members);
        let res = this.members;
        if (0 < res.length) {
          res = res.map((item, index) => ({
            ...item,
            no: res.length - index,
            kakaoChannelLbl: this.changeChannelName(item.kakaoChannel),
            // kakaoChannelLbl: this.$helper.addChannelName(item.kakaoChannel.substr(0, item.kakaoChannel.length - 1)),
            regDateLabel: this.$moment(item.createdAt).format('YYYY년 MM월DD일 a h:mm'),
            isHcpLabel: this.makeIsHcpLabel(item.isHcp)
          }));
        }
        // console.log(res);
        this.List = res;
        this.setXlsxData(res);
      });
    },
    makeIsHcpLabel(isHcp) {
      if (isHcp === '' || isHcp === null) return "";
      if (isHcp === 'W') return "대기";
      if (isHcp === 'Y') return "승인";
      if (isHcp === 'N') return "반려";
    },
    changeChannelName(channelName) {
      let returnVal =
        channelName === null ? channelName : this.$helper.addChannelName(channelName.substr(0, channelName.length - 1)); // 봇 제거
      if (returnVal === 'transplantation') {
        returnVal = '프로그랍-Transplantation';
      }
      return returnVal;
    },
    clickDate() {
      this.selectedBtn = '';
    },
    clickDateBtn(e) {
      this.selectedBtn = e;
    },
    clickReload() {
      // 컴포넌트 안에 있는 값들도 리셋시켜주어야함... 1. 컴포넌트 props 2. 컴포넌트 $refs
      this.searchValue = '';
      this.selectedBtn = 'month1'; // 초기화할때 버튼과 date 값을 초기화 해야함
      this.propsDate = [this.$moment().subtract(30, 'days'), this.$moment()];
      this.getList();
    },
    changeDate(e) {
      this.propsDate = e;
      this.getList();
    },
    // 검색어
    changeSearchWordComplete(e) {
      this.searchValue = e;
      this.getList();
    },
    changeText(e) {
      this.searchValue = e;
    },
    clickEditorBtn() {
      const reqData = new userInfoCRUDModel({});
      this.$refs.userInfoEditorPage.showModal(reqData);
    },
    tableClickRow(e) {
      console.log(e);
      this.$refs.userInfoEditorPage.showModal(e);
    },
    kakaoChannelUpdateSelect(e) {
      this.selectChannel = e;
      this.getList();
    },
    isHcpTypeUpdateSelect(e) {
      this.selectIsHcpType = e;
      this.getList();
    },
    // 저장/수정/삭제 후 처리
    userEditorCompleteSaveData(e) {
      console.log('complete');
      // 신규저장시만 리로드
      // if (e.xpk > 0) return;
      this.getList();
    },
    xlsxSave() {
      let fileHeader = this.selectChannel === '' ? 'All' : this.selectChannel.substr(0, this.selectChannel.length - 1);
      console.log('fileHeader ', fileHeader);

      this.$helper.xlsxDownload(this.xlsxData, 'sheet1', 'MemberInfoList_' + fileHeader);
    },
    setXlsxData(list) {
      this.xlsxData = [];
      for (const item of list) {
        this.xlsxData.push({
          이름: item.name,
          가입채널: item.kakaoChannelLbl,
          휴대폰번호: item.mobile,
          이메일: item.email,
          병원명: item.hospital,
          병원주소: item.hospitalAddress,
          회원가입일: item.regDateLabel,
          승인여부: item.isHcp,
          담당자: item.MR,
        });
      }
    },
    ...mapActions(['getMembers']),
  },
  computed: {
    ...mapState(['members']),
  },
};
</script>

<style>
div#member-radio {
  position: absolute;
  top: 228px;
  left: 130px;
}
</style>~/src/utils/columns