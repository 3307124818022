import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      // 사이드, 탑바 없이 풀 페이지
      component: () => import('@/components/layouts/full/LayoutFull.vue'),
      children: [
        {
          path: '/',
          redirect: "/login"
        },
        {
          path: "/login",
          component: () => import('@/components/LoginPage.vue'),
          beforeEnter: (to, from, next) => {
            if (store.getters.isAuthorized) {
              next("/userInfo");
              return;
            }
            next();
          }
        }
      ]
    },
    {
      path: '/',
      // 사이드, 텝바가 있는 페이지
      component: () => import('@/components/layouts/vertical/LayoutVertical'),
      children: [
        {
          path: "/userinfo",
          component: () => import('@/components/userInfo/UserInfoPage.vue'),
          meta: { isRequiredAuth: true }
        },
        {
          path: "/logView",
          component: () => import('@/components/logView/LogViewPage.vue'),
          meta: { isRequiredAuth: true }
        },
        {
          path: "/hcpRequestLog",
          component: () => import('@/components/hcpRequestLog/HcpRequestLogPage.vue'),
          meta: { isRequiredAuth: true }
        },
        {
          path: "/friendsCardNewsLog",
          component: () => import('@/components/logView/FriendsCardNewsLogPage.vue'),
          meta: { isRequiredAuth: true }
        },
        {
          path: "/testComp",
          component: () => import('@/components/testComp/TestComp.vue'),
          meta: { isRequiredAuth: true }
        },
        {
          path: '*',
          component: () => import('@/components/common/NotFoundPage.vue'),
          meta: { isRequiredAuth: true }
        }
      ]
    }
  ]
});

router.beforeEach(async (to, from, next) => {
  // console.log('isAuthorized', to.meta.isRequiredAuth, store.getters.isAuthorized)
  if (to.meta.isRequiredAuth && !store.getters.isAuthorized) {
    next("/login");
    return;
  }
  next();
});

export default router;
