<template>
  <div>
    <a-modal v-model="visible" width="800px" centered :afterClose="afterClose">
      <!-- 버튼 -->
      <template slot="footer">
        <a-button type="primary" @click="handleOk('Y')" :disabled="userData.isHcp === 'Y'">HCP신청승인</a-button>
        <a-button
          key="btn"
          type="primary"
          v-if="userData.id > 0"
          @click="handleOk('N')"
          :disabled="userData.isHcp === 'N'"
          >HCP신청반려</a-button
        >
        <a-button type="primary" @click="handleOk('update')">{{ userData.id === 0 ? '저장' : '수정' }}</a-button>
        <!-- <a-button key="btn" type="primary" v-if="userData.id > 0" @click="handleDelete">삭제</a-button> -->
        <a-button type="primary" @click="handleCancel">닫기</a-button>
        <!-- <a-button key="back" class="ant-btn-cancel" @click="handleCancel">취소</a-button> -->
      </template>

      <!-- body -->
      <div class="modal-content">
        <h2 class="modal-title">[{{ userData && userData.name }}] 회원상세정보</h2>
        <div>
          <a-list :split="true" class="titleTxt">
            <a-list-item>
              <a-col :span="4">회원가입일</a-col>
              <a-col :span="20">
                {{ userData.regDateLabel }}
              </a-col>
              <!--
              최근 로그인 데이터 없음  
              <a-col :span="4">최근로그인</a-col>
              <a-col :span="8">
                {{ userData.lastLoginDate }}  
              </a-col>  -->
              <!-- <a-col :span="4">로그인허용</a-col> -->
              <a-col :span="8">
                <!--  <a-switch default-checked :checked="userData.use_YN === 'Y'" @change="onChangePermit" />
                <span class="is-active">{{ userData.use_YN === 'Y' ? '허용' : '차단' }}</span> -->
              </a-col>
            </a-list-item>
            <a-list-item>
              <a-col :span="4">가입채널</a-col>
              <a-col :span="20">{{ userData.kakaoChannel }}</a-col>
            </a-list-item>
            <a-list-item>
              <a-col :span="4">*이름</a-col>
              <a-col :span="8">
                <a-input ref="refName" v-model="userData.name" />
                <!-- {{ userData.name }} -->
              </a-col>
              <!-- <a-col :span="8">
                <a-input ref="refName" v-model="userData.name" />
              </a-col> -->
              <a-col :span="4">*휴대폰번호</a-col>
              <a-col :span="8">
                <a-input ref="refMobile" v-model="userData.mobile"  />
                <!-- {{ userData.mobile }} -->
              </a-col>
            </a-list-item>
            <a-list-item>
              <!-- <a-col :span="4">아이디</a-col>
              <a-col :span="8">
                <a-input v-model="userData.userId" :disabled="true" />
              </a-col> -->
              <a-col :span="4">*이메일</a-col>
              <a-col :span="8">
                <a-input ref="refEmail" v-model="userData.email" :disabled="true" />
                <!-- {{ userData.email }} -->
              </a-col>
              <!-- </a-list-item> 
            <a-list-item>
              <a-col :span="4">의사면허번호</a-col>
              <a-col :span="8">
                <a-input v-model="userData.licenseNumber" />
              </a-col> -->
              <a-col :span="4">HCP승인여부</a-col>
              <a-col :span="8">{{ userData.isHcp }}</a-col>
              <!-- <a-col :span="4">HCP확인</a-col>
              <a-col :span="8">
                <a-switch default-checked :checked="userData.isHcp === 'Y'" @change="onChangeIsHcp" />
                <span class="is-active">{{ userData.isHcp === 'Y' ? ' 확인' : '미확인' }}</span>
              </a-col> -->
            </a-list-item>
            <a-list-item>
              <a-col :span="4">병원명</a-col>
              <a-col :span="20">
                <a-input ref="refHospital" v-model="userData.hospital" />
                <!-- {{ userData.hospital }} -->
              </a-col>
            </a-list-item>
            <a-list-item>
              <a-col :span="4">병원주소</a-col>
              <a-col :span="20">
                <a-input ref="refHospitalAddress" v-model="userData.hospitalAddress" />
                <!-- {{ userData.hospitalAddress }} -->
              </a-col>
            </a-list-item>
            <a-list-item>
              <a-col :span="4">병원주소상세</a-col>
              <a-col :span="20">
                <a-input ref="refHospitalAddressDetail" v-model="userData.hospitalAddressDetail" />
                <!-- {{ userData.hospitalAddressDetail }} -->
              </a-col>
            </a-list-item>
            <a-list-item>
              <a-col :span="4">담당자(MR)</a-col>
              <a-col :span="20">
                <a-input ref="refMR" v-model="userData.MR" />
                <!-- {{ userData.hospitalAddressDetail }} -->
              </a-col>
            </a-list-item>
            <a-list-item>
              <a-col :span="4">동의1</a-col>
              <a-col :span="8">
                <a-switch default-checked :checked="userData.agree1 === 'Y'" @change="onChangeAgree1" disabled />
                <span class="is-active">{{ userData.agree1 === 'Y' ? ' 동의' : '비동의' }}</span>
              </a-col>
              <a-col :span="4">동의2</a-col>
              <a-col :span="8">
                <a-switch default-checked :checked="userData.agree2 === 'Y'" @change="onChangeAgree2" disabled />
                <span class="is-active">{{ userData.agree2 === 'Y' ? ' 동의' : '비동의' }}</span>
              </a-col>
            </a-list-item>
          </a-list>
        </div>
      </div>
    </a-modal>
  </div>
</template>
  
<script>
import Vue from 'vue';
import { mapActions } from 'vuex';

import SelectBox from '@/components/common/v2.0/SelectBox.vue';
import DateComp from '@/components/common/DateComp.vue';
const formatService = require('@/utils/format.js');

export default {
  components: {
    SelectBox,
    DateComp,
  },
  data() {
    return {
      visible: false,
      userData: {},
      propsCalendar: this.$moment(),
      requiredMsg: formatService.requiredFieldAlertMessage(),
    };
  },
  methods: {
    showModal(userData) {
      console.log(userData);
      this.visible = true;
      this.userData = userData;
    },
    handleCancel(e) {
      this.visible = false;
      this.$emit('completeSaveData');
    },
    onChangeAgree1(checked) {
      this.userData.agree1 = checked ? 'Y' : 'N';
    },
    onChangeAgree2(checked) {
      this.userData.agree2 = checked ? 'Y' : 'N';
    },
    onChangePermit(checked) {
      this.userData.use_YN = checked ? 'Y' : 'N';
    },
    onChangeIsHcp(checked) {
      this.userData.isHcp = checked ? 'Y' : 'N';
    },
    handleOk(isHcp) {
      // 필수확인
      const D = this.userData;
      const RF = this.$refs;

      if (!this.$helper.requiredCheck(D.name, RF.refName, `이름은 ${this.requiredMsg}`)) return;
      if (!this.$helper.requiredCheck(D.mobile, RF.refMobile, `휴대전화번호는 ${this.requiredMsg}`)) return;
      if (!this.$helper.requiredCheck(D.email, RF.refEmail, `이메일은 ${this.requiredMsg}`)) return;

      this.userData.isHcp = isHcp;
      if (isHcp === 'update') {
        if (this.userData.isHcpLabel === '대기') this.userData.isHcp = 'W';
        if (this.userData.isHcpLabel === '승인') this.userData.isHcp = 'Y';
        if (this.userData.isHcpLabel === '반려') this.userData.isHcp = 'N';
      }
      console.log('this.userData', this.userData);
      // return;

      this.updateData();
    },
    async updateData() {
      this.updateMember(this).then((statusCode) => {
        // console.log('', statusCode);

        let messageLabel = this.userData.id === 0 ? 'S' : 'U';
        let messageType = 'success';
        if (statusCode !== 200) {
          messageLabel = 'E';
          messageType = 'error';
        }

        Vue.$toast.open({
          message: this.$helper.commonMessage(messageLabel),
          type: messageType,
          duration: 1000,
          dismissible: true,
        });
      });

      this.confirmLoading = false;
      this.$emit('completeSaveData', this.userData);

      this.visible = false;
    },
    handleDelete() {
      if (!confirm('[' + this.userData.name + ']회원을 승인반려 후 삭제하시겠습니까?')) return;
      this.deleteMember(this.userData.id).then((statusCode) => {
        let messageLabel = 'D';
        let messageType = 'success';
        if (statusCode !== 200) {
          messageLabel = 'E';
          messageType = 'error';
        }

        Vue.$toast.open({
          message: this.$helper.commonMessage(messageLabel),
          type: messageType,
          duration: 1000,
          dismissible: true,
        });
      });

      this.$emit('completeSaveData');
      this.visible = false;
    },
    // 창닫을때 (필요없어서 처리 않함)
    afterClose() {
      this.$emit('completeSaveData');
    },
    ...mapActions(['updateMember', 'deleteMember']),
  },
};
</script>
  
<style>
.is-active {
  display: inline-block;
  vertical-align: middle;
  padding: 0 10px;
}
.titleTxt {
  font-weight: 600;
}
</style>
  