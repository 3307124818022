import {
  SET_ACCESS_TOKEN,
  SET_MY_INFO,
  DESTROY_ACCESS_TOKEN,
  DESTROY_MY_INFO,
  SET_MEMBERS,
  SET_LOGS,
  SET_FROM_DAY,
  SET_TO_DAY,
  SET_QUERY
} from "./mutations-types";
import api from "@/api";
const moment = require("moment");

export default {
  /**
   * [인증]
   */
  //회원가입
  async signup({ commit }, payload) {
    commit(DESTROY_MY_INFO);
    commit(DESTROY_ACCESS_TOKEN);

    // const { userId, password, name, type, mobile, gender, agree } = payload.userData;

    try {
      const res = await api.post("/users/signup", payload.userData);
      if (res) {
        // const { accessToken } = res.data;
        // await commit(SET_ACCESS_TOKEN, accessToken);
        alert("회원가입 성공");
      }
    } catch (err) {
      if (err.response.status === 409) {
        return alert(err.response.data.message);
      } else {
        return alert("회원가입 에러");
      }
    }

    // try {
    //   const res = await api.get("/user/me");
    //   if (res) {
    //     return commit(SET_MY_INFO, res.data);
    //   }
    // } catch (err) {
    //   return alert("내정보보기 에러");
    // }
  },

  //로그인
  async signIn({ commit }, payload) {
    commit(DESTROY_MY_INFO);
    commit(DESTROY_ACCESS_TOKEN);

    const { userId, password } = payload;
    try {
      const res = await api.post("/users/signin", { userId, password });

      const { accessToken, type } = res.data.data;
      if (type !== "ADMIN") {
        alert("접속권한이 없습니다.");
        return
      }
      commit(SET_ACCESS_TOKEN, accessToken);
    } catch (err) {
      if (err.response.status === 401) {
        return alert("아이디 또는 비밀번호를 확인해 주세요");
      } else {
        return alert("로그인 에러 ");
      }
    }

    try {
      const res = await api.get("/users/me");

      if (res) {
        return commit(SET_MY_INFO, res.data.data);
      }
    } catch (err) {
      return alert("내정보보기 에러");
    }
  },

  //토큰이 있는 경우 내정보 가져오기
  async signinByToken({ commit, dispatch }, token) {
    commit(SET_ACCESS_TOKEN, token);
    try {
      if (token) {
        const res = await api.get("/users/me");

        if (res) {
          return commit(SET_MY_INFO, res.data.data);
        }
      } else {
        this.$router.push({ name: "SignUpPage" });
      }
    } catch (err) {
      // if (err.response.status === 401) {
      //   dispatch("signOut");
      // }
      dispatch("signOut");
      this.$router.push({ name: "SignUpPage" });
    }
  },

  //로그아웃
  signOut({ commit }) {
    commit(DESTROY_MY_INFO);
    commit(DESTROY_ACCESS_TOKEN);
  },

  /**
   * [회원 관리]
   */
  //회원 리스트
  async getMembers({ commit }, payload) {
    try {
      let { fromDay, toDay, searchValue, selectChannel, selectIsHcpType } = payload;
      const res = await api.get(`/admin/users?fromDay=${fromDay}&toDay=${toDay}&searchVal=${searchValue}&selectChannel=${selectChannel}&selectIsHcpType=${selectIsHcpType}`);
      if (res) {
        return commit(SET_MEMBERS, res.data.data);
      }
    } catch (err) {
      return alert("멤버 리스트 에러");
    }
  },

  //회원 수정
  async updateMember({ commit }, payload) {
    commit;
    // console.log('', payload);
    // return;

    const { id } = payload.userData;
    try {
      const res = await api.patch(`/admin/users/${id}`, payload.userData);

      if (res.data.statusCode === 200) {
        return res.data.statusCode;
      }
    } catch (err) {
      if (err.response.status === 403) {
        return alert(err.response.data.message);
      } else {
        return alert("회원 수정 에러");
      }
    }
  },

  //회원 삭제
  async deleteMember({ commit }, id) {
    commit;
    try {
      const res = await api.delete(`/admin/users/${id}`);
      if (res.data.statusCode === 200) {
        return res.data.statusCode;
      }
    } catch (err) {
      if (err.response.status === 403) {
        return alert(err.response.data.message);
      } else {
        return alert("회원 삭제 에러");
      }
    }
  },

  /**
   * [hcp 요청로그 관리]
   */
  //hcp요청 리스트
  async getHcpRequest({ commit }, payload) {
    try {
      let { fromDate, toDate, selectChannel } = payload;
      const res = await api.get(`admin/users/hcpLog?fromDay=${fromDate}&toDay=${toDate}&selectChannel=${selectChannel}`);
      if (res) {
        return res.data;
      }
    } catch (err) {
      return alert("hcp요청 리스트 에러");
    }
  },

  /**
   * [로그 관리]
   */
  //로그 리스트
  async getLogs({ commit }, payload) {
    try {
      let { selectEndPoint, fromDate, toDate, selectChannel, selectContent } = payload;
      const res = await api.get(`admin/${selectEndPoint}?fromDay=${fromDate}&toDay=${toDate}&selectChannel=${selectChannel}&selectContent=${selectContent}`);
      if (res) {
        return commit(SET_LOGS, res.data);
      }
    } catch (err) {
      return alert("로그 리스트 에러");
    }
  },

  async getLinkLogs({ commit }, payload) {
    try {
      let { fromDate, toDate, content, page, link, channelNameLbl } = payload;
      const res = await api.get(`admin/contentsLinkLogView?fromDay=${fromDate}&toDay=${toDate}&selectChannel=${channelNameLbl}봇&content=${content}&page=${page}&link=${link}`);
      if (res) {
        return res.data;
      }
    } catch (err) {
      return alert("로그(페이지별링크) 리스트 에러");
    }
  },

  //친구톡 카드뉴스로그 리스트
  async getFriendsLogs({ commit }, payload) {
    try {
      let { selectEndPoint, fromDate, toDate, selectCardNews } = payload;
      const res = await api.get(`admin/${selectEndPoint}?fromDay=${fromDate}&toDay=${toDate}&selectCardNews=${selectCardNews}`);
      if (res) {
        return commit(SET_LOGS, res.data);
      }
    } catch (err) {
      return alert("로그 리스트 에러");
    }
  },

  setFromDay({ commit }, value) {
    commit(SET_FROM_DAY, value);
  },

  setToDay({ commit }, value) {
    commit(SET_TO_DAY, value);
  },

  setQuery({ commit }, value) {
    commit(SET_QUERY, value);
  },
}
