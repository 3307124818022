<template>
  <div class="page-top-ui">
    <a-row>
      <a-col :span="8">
        <a-input
          id="txtSearchWorld"
          :placeholder="placeholder"
          v-model="searchWorld"
          @change="changeText"
          @keyup.enter="changeWord"
        ></a-input>
      </a-col>
      <a-col :span="3">
        <a-button type="primary" class="mar-ri10" @click="clickSearch">Search</a-button>
      </a-col>
      <a-col :span="3">
        <a-button type="primary" ghost @click="clickReset" v-if="searchWorld !== ''">초기화</a-button>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import $ from 'jquery';
export default {
  props: {
    propsSearchWorld: '',
    placeholder: {
      type: String,
      default: 'Search',
    },
  },
  data() {
    return {
      searchWorld: '',
    };
  },
  watch: {
    propsSearchWorld() {
      this.searchWorld = this.propsSearchWorld;
    },
  },
  methods: {
    changeText(e) {
      this.$emit('changeText', e.target.value);
    },
    clickReset() {
      this.searchWorld = '';
      this.clickSearch(false);
    },
    changeWord(e) {
      this.searchWorld = e.target.value;
      this.clickSearch(true);
    },
    clickSearch(opt) {
      // 필수
      const sN = $('#txtSearchWorld');
      // if (opt && this.searchWorld === '') {
      //   this.$message.warning('검색어를 입력해주세요', 0.5);
      //   sN.focus();
      //   return;
      // }
      this.$emit('changeSearchWordComplete', this.searchWorld);
    },
  },
};
</script>

<style lang="scss">
@import '~@/assets/scss/common';
.page-top-ui {
  .ant-row {
    .ant-col {
      .ant-input {
        width: 100%;
      }
      .ant-btn {
        min-width: 100%;
      }
      .ant-select {
        width: 100%;
      }
    }
  }
}
</style>